import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { ButtonSizes, ButtonStyleTypes } from 'ui-button';

@Component({
	selector: 'ui-modal',
	templateUrl: './modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
	@Input() isOpen = false;
	@Input() mobileHeaderTitle = '';
	@Input() isSheet = false;
	@Input() headerStyleModifiers = '';

	@Output() byClose = new EventEmitter<void>();

	readonly buttonStyleTypes = ButtonStyleTypes;
	readonly buttonSizes = ButtonSizes;

	toggle(state?: boolean) {
		if (state === undefined) {
			state = !this.isOpen;
		}
		if (!state) {
			this.byClose.emit();
		}
	}
}
