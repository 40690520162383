import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UiButtonModule } from 'ui-button';
import { UiIconModule } from 'ui-icon';
import { ModalComponent } from './modal.component';

@NgModule({
	declarations: [ModalComponent],
	exports: [ModalComponent],
	imports: [
		CommonModule,
		TranslateModule.forChild(),
		UiIconModule,
		UiButtonModule,
	],
})
export class UiModalModule {}
