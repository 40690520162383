<div
	*ngIf="isOpen"
	class="fixed inset-0 bg-purple-900/07"
	(click)="toggle(false)"
></div>
<div
	*ngIf="isOpen"
	class="fixed inset-x-0 bottom-0 z-10 max-h-9/10 w-full overflow-auto rounded-t-box bg-white sm:inset-x-auto sm:border sm:border-grey-100 sm:shadow-dropdown"
	[ngClass]="{
		'sm:bottom-auto sm:left-1/2 sm:top-10 sm:max-h-[calc(100%_-_2.5rem_-_2.5rem)] sm:max-w-xl sm:-translate-x-1/2 sm:rounded-box':
			!isSheet,
		'sm:right-0 sm:top-0 sm:h-full sm:max-h-none sm:max-w-lg sm:rounded-none':
			isSheet
	}"
>
	<div
		class="flex items-center justify-between p-3 sm:p-6 {{
			headerStyleModifiers
		}}"
	>
		<span class="text-xl font-bold text-purple-500">
			{{ mobileHeaderTitle }}
		</span>
		<ui-button
			class="flex h-10 w-10 shrink-0 items-center justify-center self-start"
			[attr.aria-label]="'global.close' | translate"
			[styleType]="buttonStyleTypes.LINK_BLUE"
			[size]="buttonSizes.ICON"
			(click)="toggle(false)"
		>
			<ui-icon name="close"></ui-icon>
		</ui-button>
	</div>
	<div class="p-3 pb-6 pt-0 sm:p-6 sm:pt-0">
		<ng-content></ng-content>
	</div>
</div>
